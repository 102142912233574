.category{
    width: 180px;
    height: 180px;
    text-align: center;
    margin-right: 15px;
}
.category:hover{
    cursor: pointer;
}
.image-container img{
    width: 180px;
    height: 180px;
    margin-bottom: 5px;
    object-fit: contain;
}
.image-container{
    width: 180px;
    height: 180px;
}
@media (max-width: 990px) {
    .category{
        width: 100px;
        height: 150px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .image-container img{
        width: 100px;
        height: 100px;
    }
    .image-container {
        width: 100px;
        height: 100px;
    }
}