section{
  background: rgb(255, 255, 255);
  padding: 5px;
}
.carousel {
  width: 100%;
  overflow: hidden;
}
.carousel_outer {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -100;
}
.carousel-control-next {
  height: 0px;
  border: none;
  color: black;
  font-size: 30px;
  position: absolute;
  top: 50%;
  z-index: 10;
  right: -30px;
}
.carousel-control-prev {
  height: 0px;
  border: none;
  color: black;
  font-size: 30px;
  position: absolute;
  top: 50%;
  z-index: 10;
  left: -30px;
}
.carousel-item img{
  width: 100%;
}
.carousel-control-next:hover{
  color: black;
}
.carousel-control-next:focus{
  color: black;
}
.carousel-control-prev:hover{
  color: black;
}
.carousel-control-prev:focus{
  color: black;
}

.category-hero-section{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.category-item{
  background: #000;
  margin-right: 20px;
  width: 320px;
  height: 200px;
  margin-bottom: 30px;
  transition-duration: 0.2s;
}
.category-item-heading{
  color:#fff;
  position: relative;
  font-size: 22px;
  font-weight: 600;
  bottom: 80%;
  left: 20px;
  z-index: 100;
}
.category-item-subtitle{
  position: relative;
  bottom: 80%;
  left: 20px;
  color: #fff;
  font-size: 17px;
}
.btn-shopnow{
  position: relative;
  bottom: 80%;
  left: 20px;
  color: #fff;
  font-size: 15px;
  background:#ec6164;
  border: none;
  padding: 7px 15px 7px 15px;
  font-weight: 600;
  border-radius: 2px;
  transition-duration: 0.1s;
}
.btn-shopnow:hover{
  background: #cc494b;
  transition-duration: 0.1s;
}
.circle img{
  z-index: 50;
  opacity: 0.5;
  width: 320px;
  height: 200px;
  object-fit: fill;
}

.bakingWorkshop {
  margin-left: 3rem;
  margin-top: 20px;
}


h1 {
  font-size: 25px;
  color: #242b2e;
  font-weight: 550;
}

.category-collection{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.view-all-button{
  font-size: 13px;
  font-weight: 300;
  background: rgb(142, 255, 138);
  padding: 4px;
}

.courses-carousel {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.course {
  margin-right: 20px;
  width: 250px;
  height: 320px;
  box-shadow: 2px 2px 6px rgb(216, 216, 216);
  text-align: center;
  margin-top: 20px;
  border: 1px solid rgb(216, 216, 216);
  transition-duration: 0.2s;
}
.course:hover{
  transform:scale(1.02);
  transition-duration: 0.2s;
}

.card-image-container{
  width: 246px;
  height: 220px;
}

.course img {
  object-fit: contain;
  width:246px;
  height: 220px;
}
.heading {
  font-size: 16px;
  color: #1b1b1b;
  font-weight: 600;
  margin-top: 5px;
}
.price {
  color: #ec6164;
  font-weight: 700;
  margin-top: -10px;
}
.price span {
  color: #b0b0b0;
  text-decoration: line-through;
  font-weight: 400;
}
.enroll {
  width: 100%;
  border: none;
  padding: 5px;
  color: white;
  background: #7fb62f;
  font-weight: 600;
  margin-top: -4px;
  transition-duration: 0.1s;
}
.enroll:hover{
  background: #608b1f;
  transition-duration: 0.1s;
}

.course-images{
  margin-top: 1.2rem;
}
.course-images p{
  font-size: 17px;
  color: #686868;
}
.imagebox{
  width: 280px;
  height: 280px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}
.imagebox img{
  padding: 8px;
  width: 280px;
  height: 280px;
  object-fit: contain;
}
.course-images-grid{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.form-input{
  width: 50%;
  margin-bottom: 10px;
  height: 40px;
  padding: 10px;
  border-radius:5px;
  border: none;
  border: 1px solid rgb(214, 214, 214);
}

#submit-button{
  background: #7FB62F;
  border: none;
  color: white;
}

.textarea {
  height: 60px;
}

.picktype{
  width: 50%;
  border-radius:5px;
  border: 1px solid rgb(214, 214, 214);
  padding: 10px 15px 5px 15px;
  background: white;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 400;
}

#delivery{
  transform: scale(1.2);
}
#pickup{
  transform: scale(1.2);
}
.labelfordeliveryoptions{
  margin-left: 10px;
}
.leftfees{
  float: right;
  margin-right: 10px;
}


.course-details-menu{
  margin-left: 70px;
  margin-top: 2.2rem;
}
h2{
  font-size: 25px;
  font-weight: 600;
}
.course-details-menu p{
  color: #555555;
  inline-size: 90%;
}

.course-details-text{
  margin-left: 70px;
  margin-top: 4rem;
}
.course-details-text ul {
  list-style-type: none;
  font-size: 16px;
  margin-top: 10px;
}
.course-details-text p{
  color: #ec6164;
  font-size:25px;
  font-weight: bold;
}
.course-details-text span{
  color: #a0a0a0;
  text-decoration: line-through;
  font-size: 15px;
  font-weight: 400;
}
.enrollnow {
  width: 30rem;
  height: 4rem;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  color: white;
  background: #EC6164;
  font-weight: bold;
  transition-duration: 0.2s;
}
.enrollnow:hover {
  background: #ff3437;
}

.viewimages {
  width: 30rem;
  height: 4rem;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  color: white;
  background: #7FB62F;
  font-weight: bold;
  transition-duration: 0.2s;
  margin-top: 20px;
}
.viewimages:hover {
  background: #618f1c;
}

.course-details-image img{
  width: 25%;
  border-radius: 10px;
  float: right;
  margin-right: 6%;
}

.allProducts{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-own{
  outline: 7px solid rgb(255, 255, 255);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background: white;
  width: 280px;
  border-radius: 3px;
}
.card-image-container-products{
  width: 280px;
  height: 280px;
}
.card-image-container-products img{
  width: 280px;
  height: 280px;
  object-fit: contain;
}
.heading-product{
  font-weight: 500;
  margin: 5px;
}
.price-product{
  color: #ec6164;
  margin-left: 5px;
  font-weight: 600;
}
.price-product span{
  color: #b0b0b0;
  text-decoration: line-through;
  font-weight: 400;
}
.occasionSelected{
  font-size: 7px;
}

.filter-container {
  margin-top: 13px;
  background: rgba(104, 102, 102, 0.8);
  width: 92vw;  
  margin-bottom: 20px;
  height: 45px;
  display: flex;
  justify-content: center;
}
.filter-options{
  background: none;
  color: white;
}

.dropbtn {
  background: none;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-own {
  position: relative;
  display: inline-block;
}

.dropdown-content-own {
  display: none;
  position: absolute;
  background-color: rgb(104, 102, 102);
  min-width: 158px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-own a {
  color: rgb(189, 189, 189);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content-own a:hover {background-color: rgb(58, 58, 58)}

.dropdown-own:hover .dropdown-content-own {
  display: block;
}

.dropdown-own:hover .dropbtn {
  background-color: rgb(58, 58, 58);
}

.fa-chevron-down{
  font-size: 7px;
  position: relative;
  top: -2px;
  left: 3px;
}
@media (max-width: 900px) {

  .card-own{
    border: 0.1px solid rgb(224, 224, 224);
    outline: none;
    width: 50vw;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    border-radius: 0px;
  }
  .card-image-container-products{
    width: 100%;
    height: 100%;
  }
  .card-image-container-products img{
    width: 100%;
    height: 150px;
    object-fit: contain;
  }

  .heading-container{
    width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: scroll;
  }
  .heading-container p {
    font-size: 14px;
  }
  .category-item{
    width: 90vw;
    height: 200px;
    margin: 0px;
    margin-bottom: 30px;
  }
  .circle img{
    width: 90vw;
    height: 200px;
  }

  .carousel {
    width: 100%;
    margin: 0px;
  }

  .carousel-control-prev {
    left: -10px;
    font-size: 15px;
  }
  .carousel-control-next {
    right: -10px;
    font-size: 15px;
  }

  .bakingWorkshop {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }

  .courses-carousel {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .course {
    margin-left: 0px;
    width: 300px;
  }
  .course img{
    width: 296px;
  }
  .enroll {
    width: 100%;
  }

  .enrollnow{
    width: 95%;
  }  

  .viewimages{
    width: 95%;
  }

  .course-details-text{
    position: relative;
    margin-left: 0px;
    text-align: center;
    top: -20px; 
  }

  .course-details-image img{
    position: relative;
    top: -50px;
    width: 100%;
    border-radius: 0px;
    margin-top: 0px;
    left: 25px;
  }

  .course-details-menu{
    margin-left: 20px;
    margin-top: 1rem;
  }
  .heading{
    text-align: center;
  }
  .heading h2{
    font-size: 20px;
  }

  .imagebox{
    width: 170px;
    height: 170px;
  }
  .imagebox img{
    padding: 3px;
    width: 170px;
    height: 170px;
  }

  .category-collection{
    justify-content: center;
  }

  .form-input{
    width: 95%;
  }

  .picktype{
    width: 95%;
  }
  

}
@media (max-width: 380px) {
  .imagebox{
    width: 90%;
    height: 90%;
  }
  .imagebox img{
    padding: 3px;
    width: 90%;
    height: 90%;
  }
}
