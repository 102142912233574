.nav-item{
    float: left;
    margin-left: 3rem;
    color: #EC6164;
    text-transform: uppercase;
    font-weight: bold;
}

.thisul{
    position: absolute;
    right: 50px;
    list-style-type: none;
    top: 25px;
}

.fa-shopping-cart{
    font-size: 1.5rem;
}

.dropdown{
    position: absolute;
    background-color: #f9f9f9;
    min-width: 10rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding-right: 3rem;
    z-index: 1;
    width: 5rem;
    top: 1.5rem;
    text-align: center;
    right: 11.6rem;
}
#products:hover + .dropdown{
    display: block;
    background-color: #f9f9f9;
    min-width: 10rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding-right: 3rem;
    z-index: 1;
    width: 5rem;
    text-align: center;
    right: 10rem;
}
.dropdown:hover{
    background-color: #f9f9f9;
    display: block;
}
.navbar-toggler:active,
.navbar-toggler:focus
{
    border: none;
    outline: none;
}
.navbar-toggler {
    border: none;
}

nav{
    background-color: #000000;
    position: fixed;
    top: 0px;
    z-index: 140;
}

a{
    text-decoration: none;
    color: black;
}

a:hover{
    text-decoration: none;
    color: black;
}

.navbar-brand img{
    width: 53px;
    margin: auto;
}

.barsicon{
    position: absolute;
    margin-top: -40px;
    margin-left: 80vw;
}

.navbar-brand{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}
.navbar-brand p{
    position: absolute;
    top: 25px;
    left: 9rem;
    color: #EC6164;
    font-weight: bold;
}

@media(max-width: 990px) {

    .navbar-brand p{
        position: absolute;
        top: 25px;
        left: 6.2rem;
    }

    .thisul{
        list-style-type: none;
        text-align: center;
        position: static;
        width: 200px;
        margin-left: -20px;
        line-height: 2.5rem;
    }

    .navbar-brand img{
        margin-left: 1.5rem;
    }

}

/* ul{
    width: 100%;
    list-style-type: none;
    padding: 0px;    
    margin: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}

li{
    margin-left: 3rem;
    float: left;
    display: block;
} */
