.footer{
    text-align:center;
    background: #e9e9e9;
    width: 100%;
    color: rgb(43, 43, 43);
    border-top: 1px solid rgb(204, 204, 204);
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -10px;
    margin-top: 40px;
  }
.footer p{
    color: rgb(43, 43, 43);
    margin-bottom: 0px;
}