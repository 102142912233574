.side-banner{
    background: rgb(24, 24, 24);
    margin-top: 50px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.heading-side-banner{
    font-size: 43px;
    color: #fff;
    font-weight: 700;
}
.subhead-side-banner{
    font-size: 20px;
    width: 40vw;
    color: rgb(207, 207, 207);
}
.btn-side-banner{
    background: #fff;
    padding: 15px 80px 15px 80px;
    font-size: 18px;
    border: none;
    border-radius: 2px;
    position: relative;
    top: 5px;
    transition-duration: 0.3s;
}
.btn-side-banner:hover{
    transition-duration: 0.3s;
    top: 0px;
}
.content{
    padding-left: 5vw;
}
.banner-image img{
    height: 80vh;
    float: right;
    z-index: 2;
    transform: scale(1.1);
}
.banner-image {
    position: relative;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-outline{
    border: 5px solid rgb(49, 49, 49);
    height: 55vh;
    width: 40vw;
    position: absolute;
    z-index: 0;
    margin-top: 5px;
}


@media (max-width: 990px) {
    .side-banner{
        width: 100vw;
        display: block;
    }
    .banner-image img{
        height: 43vh;
    }
    .banner-outline{
        width: 80vw;
        height: 30vh;
    }
    .banner-image{
        width: 100vw;
        margin-top: 30px;
    }
    .subhead-side-banner{
        width: 90vw;
    }
    .heading-side-banner{
        font-size: 37px;
    }
    .content{
        padding-top: 25px;
    }
}